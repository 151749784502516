// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: initial;
  text-decoration: none;
}
body {
  font-size: 25px;
}

.border {
  border: 2px solid purple !important;
}

.container {
  max-width: 1440px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: auto;
}
input {
  outline: none;
  padding: 5px 10px;
  border-radius: 5px;
}

body {
  background-color: #f1f2f2;
}

// Remove arrow from input number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input-control {
  outline: none;
  padding: 5px 10px;
  border: solid 1px #ccc;
  border-radius: initial;
}
.select-control {
  .css-1s2u09g-control {
    border-radius: 0px;
    border: none;
  }
  .css-1s2u09g-control {
    border-radius: 0px;
    border: none;
  }
  .css-1pahdxg-control {
    border-radius: 0px;
    border: none;
  }
}
.border-danger {
  border: 1px solid #a41120 !important;
}
select {
  background-color: white;
}
