.checkpost-home {
  min-height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: white;
  .logo {
    max-width: 70px;
    img {
      width: 70px;
      height: 70px;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;

    .content {
      display: flex;
      align-items: center;
      gap: 10px;
      .text-container {
        max-width: 250px;
      }
    }
  }
  .welcome-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .checkpoint-footer {
    position: fixed;
    bottom: 15px;
    width: 100%;
    // left: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    // padding: 0 20px;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 40px;
    .left {
      display: flex;
      gap: 10px;
      width: 0;
      .text-container {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
    img {
      width: 16px;
      height: 16px;
    }
    input {
      background-color: transparent;
      border: solid 1px red;
      // color: white;
      color: transparent;
      border: none;
      &:focus {
        border: solid 1px green;
        border: none;
      }
    }
  }
  .content {
    // min-height: 100vh;
    // padding: 20px 0;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    .footer-text {
      display: flex;
      justify-content: flex-end;
      align-self: end;
      margin-right: 20px;
      p {
        font-weight: 500;
      }
    }
  }
  .login-body {
    // height: 80vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: calc(100vh - 59px);
    @media (max-width: 600px) {
      justify-content: center;
      h1 {
        display: none;
      }
    }
    .text-content {
      .new {
        @media (max-width: 600px) {
          display: none;
        }
      }
      .medium-text {
        @media (max-width: 600px) {
          display: none;
        }
      }
    }
    h1 {
      margin: 15px 0;
      font-weight: 700;
      &.new {
        font-weight: 700;
      }
    }
    h2 {
      font-weight: 600;
    }
    .form-content {
      max-width: 400px;
      min-width: 330px;

      &.new {
        max-width: 285px;
      }

      .new.menu-container {
        margin-top: 40px;

        .login-content {
          transition: 0.3s all ease-in-out;
          background-color: white;
          padding: 8px 0px 10px 20px;
          margin-bottom: 20px;
          transition: all 0.5s ease;
          &:hover {
            transform: scale(1.1);
          }
          &:last-child {
            margin-bottom: initial;
          }
          a {
            display: flex;
            align-items: center;
            column-gap: 15px;
            .logo-container {
              width: 40px;
              height: 40px;
              img {
                width: 40px;
                height: 40px;
              }
            }
            .text-container {
              p {
                font-weight: 700;
                font-size: 20px;
                margin-bottom: 0;
                color: #2e3192;
              }
            }
          }
        }
      }
      .text-content {
        text-align: center;
        margin-bottom: 15px;
      }
      .logo {
        margin-inline: auto;
        margin-bottom: 10px;
      }
      .form-container {
        background-color: white;
        color: black;
      }
      .form-header {
        padding: 25px 10px;
        color: #0b5099;
        text-align: center;
      }
      .form-body {
        padding: 10px 25px;
      }
      .form-input {
        margin-bottom: 15px;
      }
      .fogotPasswordForm-input {
        display: flex;
        column-gap: 5px;
        input {
          width: 15px;
        }
      }
      label {
        display: block;
        margin-bottom: 5px;
      }
      input {
        width: 100%;
        outline: none;
        border: none;
        background-color: white;
        border-bottom: solid 1px #999;
        border-radius: 0;
      }
      select {
        width: 100%;
        padding: 4px;
        border: 0;
        border-bottom: 1px solid #999;
      }
      select:focus-visible {
        outline: 0;
      }
      .button-container {
        padding: 25px;
        button {
          width: 100%;
          padding: 10px;
          background-color: #0b5099;
          color: white;
          outline: none;
          border: none;
          &:hover {
            background-color: #08407b;
          }
          &:active {
            background-color: #042141;
          }
        }
      }
    }
  }
}
.password-input {
  display: flex;
  position: relative;
  svg {
    position: absolute;
    height: 10px;
    right: 0;
    top: 15px;
    cursor: pointer;
    margin: 13px;
  }
}
